import { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { svgs } from '../assets/svgs';
import { tracking } from '../utils/tracking';

const pages = [
   { name: 'home', link: '/', trackingId: 356, },
   { name: 'about us', link: 'about-us', trackingId: 357, },
   { name: 'summits', link: 'summits', trackingId: 358, },
   { name: 'contact us', link: 'contact-us', trackingId: 359, },
   { name: 'testimonials', link: 'testimonials', trackingId: 360, },
]

export const NavBar = () => {
   const [isMenuOpen, setIsMenuOpen] = useState(false)
   const [isScrolledDown, setIsScrolledDown] = useState(false)

   useEffect(() => {
      const checkScroll = () => {
         setIsScrolledDown(window.scrollY > 0)
      }
      window.addEventListener('scroll', checkScroll)
      return () => {
         window.removeEventListener('scroll', checkScroll)
      }
   }, [])

   return (
      <div className={isScrolledDown ? 'nav-bar fill-color' : 'nav-bar'}>

         <section className='logo-section' >
            <Link onClick={() => { setIsMenuOpen(false); tracking(355) }} to='/'>{svgs.youngStartupsLogo}</Link>
         </section>
         <section className={isMenuOpen ? 'nav open' : 'nav'}>
            {
               pages.map(page =>
                  <NavLink onClick={() => { setIsMenuOpen(false); tracking(page.trackingId) }} className='link' key={page.name} to={page.link}>{page.name}</NavLink>
               )
            }
            <div className='btns mobile'>
               {/* <a onClick={() => { setIsMenuOpen(false); tracking(361) }} className='btn' href="http://youngstartup.com/upcoming">Upcoming Summit</a> */}
               <NavLink onClick={() => { setIsMenuOpen(false); tracking(361) }} className='btn' to='summits'>Upcoming Summits</NavLink>
            </div>
         </section>
         <section className='btns desktop'>
            {/* <a onClick={() => { setIsMenuOpen(false); tracking(361) }} className='btn' href="http://youngstartup.com/upcoming">Upcoming Summit</a> */}
            <NavLink onClick={() => { setIsMenuOpen(false); tracking(361) }} className='btn' to='summits'>Upcoming Summits</NavLink>
         </section>
         <section className='hamburger-section'>
            <button onClick={() => setIsMenuOpen(prev => !prev)}>
               {isMenuOpen ? svgs.clear : svgs.hamburger}
            </button>
         </section>
      </div >
   )
}





